import axios from 'axios';
import React, { useEffect, useState } from 'react'
import requests from '../Requests';

const Main = () => {
    const [movies, setMovies] = useState([]);

    const movie = movies[Math.floor(Math.random() * movies.length)]

    useEffect(() => {
        axios.get(requests.requestPopular).then((response)=>{
            setMovies(response.data.results);
        })
    }, [])
    
    const truncateString = (str, num) => {
        if(str?.length > num) {
            return str.slice(0, num) + '...'
        } else {
            return str;
        }
    };

    return (
    <div className='w-full h-[550px] text-white'>
        <div className='w-full h-full'>
        <div className='absolute w-full h-[550px] bg-gradient-to-r from-black'></div>
        <img className='w-full h-full object-cover' src={`https://image.tmdb.org/t/p/original/${movie?.backdrop_path}`} alt={movie?.title} />
        <div className='absolute w-full top-[20%] p-4 md:p-8'>
                <h1 className='text-3xl md:text-5xl font-bold pb-5'>{movie?.title}</h1>
            <div>
                <button className='border bg-gray-300 text-black border-gray-300 py-2 px-5 rounded-md hover:bg-green-600 border-none ease-in duration-200 hover:text-white'>Play</button>
                <button className='border text-white border-gray-300 py-2 px-5 ml-4 rounded-md hover:text-green-600 hover:border-green-600 hover:ease-in duration-200'>Watch Later</button>
            </div>
            <p className='text-gray-400 text-sm pt-5'>Released: {movie?.release_date}</p>
            <p className='w-full md:max-w-[70%] lg:max-w-[50%] xl:max-w-[35%] text-gray-200 pt-5 text-justify'>{truncateString(movie?.overview, 150)}</p>
        </div>
        
        </div>
    </div>
  )
}

export default Main